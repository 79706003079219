import React from 'react';
import { Box, Grid } from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

const gettouch = [
    {
        icon: <PlaceOutlinedIcon />,
        text: "No: 25, 15th Main Road, Anna Nagar West, Chennai 600040"
    },
    {
        icon: <EmailOutlinedIcon />,
        text: "contactus@koinnovation.com"
    },
    {
        icon: <ModeCommentOutlinedIcon />,
        // text: "+91 73588 69821"
        text: "+91 82200 03007"
    }
];

const social = [
    {
        icon: <LinkedInIcon />,
        url : "https://www.linkedin.com/company/kovins/"
    },
    {
        icon: <FacebookRoundedIcon />,
        url : "#!"
    },
    {
        icon: <InstagramIcon />,
        url : "#!"
    },
    {
        icon: <TwitterIcon />,
        url : "#!"
    }
];


const quicklinks = [
    {
        url: '/service/sap',
        text: "Services"
    },
    {
        url: '/about',
        text: "About Us"
    },
    {
        url: '/careers',
        text: "Careers"
    },
    {
        url: '/contact',
        text: "Contact Us"
    },
];

function Footer() {
    const getYear = () => {
        return new Date().getFullYear();
    }
    return (
        <>
            <section className='project_highlight' style={{ paddingBottom: '2em', paddingTop: '2em' }}>
                <Box className='container'>
                    <Box className='start_project' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {/* <h2 className=''>Have a Project or a question? We'd love to hear from you</h2> */}
                        <button className='primary_btn dark' onClick={() => window.location.replace('/contact#enquiry_contact')} style={{ marginLeft: 20 }}>Connect With Us</button>
                    </Box>
                </Box>
            </section>
            <section className='footer dark ' style={{ paddingBottom: 0 }}>
                <Box className='footer_top' sx={{ paddingBottom: 4 }}>
                    <Box className='container'>
                        <Grid container spacing={4}>
                            <Grid item md={3}>
                                <Box className='footer_item' sx={{position:'relative',top:'40%'}}>
                                    <img src={require('../assets/images/white_logo.png')} className='flogo' width={'100%'} />
                                    {/* <Box className='content'>The world around us change incredibly every passing day. There has never been more pressing need for organizations to rethink and realign their businesses for a changed world. We support the organizations to adopt the changes for benefits and future. </Box> */}
                                </Box>
                            </Grid>
                            <Grid item md={4}>
                                <Box className='footer_item' sx={{ paddingLeft: 2 }}>
                                    <h5>Get In Touch</h5>
                                    {/* <span>Chennai</span>
                                    <ul className='footer_list contact'>
                                        <li >
                                            <span className='icon'><PlaceOutlinedIcon /></span>
                                            No: 25, 15th Main Road, Anna Nagar West, Chennai 600040
                                        </li> 
                                    </ul> */}
                                    <span>Trichy</span>
                                    <ul className='footer_list contact'>
                                        <li >
                                            <span className='icon'><PlaceOutlinedIcon /></span>
                                            No 11, 10th Cross Thillai Nagar, Trichy-620018
                                    </li>

                                        <li >
                                            <span className='icon'> <EmailOutlinedIcon /></span>
                                            contactus@koinnovation.com
                                        </li>

                                        <li >
                                            <span className='icon'><ModeCommentOutlinedIcon /></span>
                                            +91 7200577888
                                        </li> 
                                    </ul>
                                </Box>
                            </Grid>
                            <Grid item md={2}>
                                <Box className='footer_item' sx={{ paddingLeft: 2 }}>
                                    <h5>Explore Links</h5>
                                    <ul className='footer_list links'>
                                        {quicklinks.map((list, index) =>
                                            <li key={index}>
                                                <a href={list.url}>{list.text}</a>
                                            </li>
                                        )}
                                    </ul>
                                </Box>
                            </Grid>
                            <Grid item md={3}>
                                <Box className='footer_item'>
                                    <h5>Join Us</h5>
                                    <ul className='footer_list1 social'>
                                        {social.map((list, index) =>
                                            <li key={index}>
                                                <a href={list.url}  target='_blank' >{list.icon}</a>
                                            </li>
                                        )}
                                    </ul>
                                </Box>

                                {/* <iframe 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d971.4635380479488!2d80.18280650976527!3d13.108424500000016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52638941faaaab%3A0x8d535599162a1919!2sKO%20innovation!5e0!3m2!1sen!2sin!4v1667804035291!5m2!1sen!2sin" 
                                width="100%" height="100%"  
                                allowfullscreen="" loading="lazy" 
                                style={{ border : 0 }}
                                referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <Box className='footer_bottom' sx={{ paddingBottom: 3, paddingTop: 3 }}>
                    <Box className='container'>
                        <Grid container >
                            <Grid item md={6}>
                                <Box className='copyright'>Copyright © {getYear()} KO innovation.</Box>
                            </Grid>
                            <Grid item md={6} style={{ textAlign: 'right' }}>
                                <Box className='terms_policy'>
                                    <a href="#!">Terms & Conditions</a>
                                    <a href="#!">Privacy Policy</a>
                                    <a href="#!">Sitemap</a>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </section>
        </>
    )
}

export default Footer
